

<script>
import UIInput from '@components/uiinput';
import http from '@commons/http';
import { required, minLength, sameAs, email } from 'vuelidate/lib/validators';
import { isStrongPassword, validCPF, isValidDateBR } from '@commons/validations';
import moment from 'moment';

export default {
	name: 'LoginSignup',
	components:{
		UIInput
	},
	data(){
		return {
			form: {
				cpf: "",
				nascimento: "",
				telefone: "",
				email: "",
				novaSenha: "",
				confirmaNovaSenha: ""
			},
			loading: false
		}
	},
	methods:{
		goto(){
			this.$router.push({name: 'login.success'});
		},
		handleRenewSubmit(){
			this.$v.form.$touch();

			if (this.$v.form.$pending || this.$v.form.$error) return;

			let data_params = new URLSearchParams(
				Object.entries({
					cpf: this.form.cpf,
					telefone: this.form.telefone,
					nascimento: moment(this.form.nascimento, 'DD/MM/YYYY', true).format("YYYY-MM-DD"),
					novo_email: this.form.email,
					senha: this.form.novaSenha,
				})
			).toString();

			http.post('/v1/auth/renew-pass', data_params).then(() => {
				if(this.notification) this.notification.close();
				this.goto();
			}, ({data})=>{
				this.notification = this.$buefy.notification.open({
					indefinite: true,
					queue: false,
					message: data.message,
					position: 'is-top',
					type: 'is-danger',
					hasIcon: true
				});
			});
		}
	},
	validations:{
		form:{
			cpf: {
				required,
				validCPF
			},
			nascimento: {
				required,
				minLength: minLength(10),
				isValidDateBR
			},
			telefone: {
				required,
				minLength: minLength(15)
			},
			email: {
				required,
				email
			},
			novaSenha: {
				required,
				minLength: minLength(8),
				isStrongPassword
			},
			confirmaNovaSenha: {
				sameAsPassword: sameAs('novaSenha'),
				required,
				minLength: minLength(8),
				isStrongPassword
			}
		}
            
	}
}
</script>

<template>
	<div class="container">
		<div class="has-text-centered">
			<h2 class="title is-1">
				Que bom ter você na {{ $store.state.ie.shortname }}!
			</h2>
			<h3 class="subtitle">
				Preencha seus dados para cadastrar um novo e-mail e uma nova senha:
			</h3>
		</div>

		<div class="columns mt-5">
			<div class="column is-6-desktop is-offset-3-desktop is-4-fullhd is-offset-4-fullhd">
				<form
					novalidate
					class="form"
					@submit.prevent="handleRenewSubmit"
				>
					<div class="columns is-multiline">
						<div class="column is-12">
							<UIInput 
								id="cpf" 
								v-model="form.cpf" 
								v-mask="'###.###.###-##'"
								label="CPF"
								placeholder="Seu CPF"
								:validation="$v.form.cpf"
								:error="{
									invalid: '*CPF inválido.',
									required: '*Campo obrigatório.'
								}"
								required
							/>
						</div>

						<div class="column is-12">
							<UIInput 
								id="nascimento" 
								v-model="form.nascimento" 
								v-mask="'##/##/####'"
								label="Data nascimento"
								placeholder="Sua data de nascimento"
								:minlength="10"
								:validation="$v.form.nascimento"
								:error="{
									required: '*Campo obrigatório.',
									minLength: '*Data de nascimento inválida.',
									invalid: '*Data de nascimento inválida.',
								}"
								required
							/> 
						</div>

						<div class="column is-12">
							<UIInput 
								id="telefone" 
								v-model="form.telefone" 
								v-mask="'(##) #####-####'"
								label="Telefone"
								type="phone"
								:minlength="11"
								placeholder="Telefone"
								:validation="$v.form.telefone"
								:error="{
									required: '*Campo obrigatório.',
									minLength: '*Número de telefone inválido.',
								}"
								required
							/>
						</div>

						<div class="column is-12">
							<UIInput 
								id="email"
								v-model="form.email" 
								label="Novo E-mail"
								placeholder="Novo E-mail"
								type="email"
								:validation="$v.form.email"
								:error="{
									required: '*Campo obrigatório.',
									email: '*Email no formato inválido.'
								}"
								required
							/>
						</div>

						<div class="column is-12">
							<UIInput 
								id="nova_senha" 
								v-model="form.novaSenha"
								type="password"
								label="Nova Senha"
								:validation="$v.form.novaSenha"
								:error="{
									minLength: '*A quantidade de caracteres é menor que 8.',
									invalid: '*Campo inválido.',
									required: '*Campo obrigatório.',
									strong: '*A senha deve conter números e letras maiúsculas e minúsculas.'
								}"
								required
							/>

							<div class="columns">
								<div class="column is-paddingless">
									<p class="paragraph pt-4 pl-5">
										A senha deve conter: 8 digitos, letras minúsculas e<br>
										maiúsculas, números e algum caracter especial
									</p>
								</div>
							</div>
						</div>

						<div class="column is-12">
							<UIInput 
								id="confirma_nova_senha" 
								v-model="form.confirmaNovaSenha"
								type="password"
								label="Confirma Nova Senha"
								:validation="$v.form.confirmaNovaSenha"
								:error="{
									minLength: '*A quantidade de caracteres é menor que 8.',
									invalid: '*Campo inválido.',
									required: '*Campo obrigatório.',
									strong: '*A senha deve conter números e letras maiúsculas e minúsculas.',
									sameAs: '*A senha e a confirmação devem ser a mesma.'
								}"
								required
							/>
						</div>

						<div class="column is-12 has-text-centered mt-5">
							<button class="button is-primary is-rounded">
								Alterar e-mail
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<style scoped>
    .paragraph{
        font-size: 12px;
        color: var(--dark-blue);
        padding: 1em 0 0 1em;
    }
</style>