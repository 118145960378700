var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"has-text-centered"},[_c('h2',{staticClass:"title is-1"},[_vm._v(" Que bom ter você na "+_vm._s(_vm.$store.state.ie.shortname)+"! ")]),_c('h3',{staticClass:"subtitle"},[_vm._v(" Preencha seus dados para cadastrar um novo e-mail e uma nova senha: ")])]),_c('div',{staticClass:"columns mt-5"},[_c('div',{staticClass:"column is-6-desktop is-offset-3-desktop is-4-fullhd is-offset-4-fullhd"},[_c('form',{staticClass:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleRenewSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('UIInput',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"cpf","label":"CPF","placeholder":"Seu CPF","validation":_vm.$v.form.cpf,"error":{
								invalid: '*CPF inválido.',
								required: '*Campo obrigatório.'
							},"required":""},model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}})],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"id":"nascimento","label":"Data nascimento","placeholder":"Sua data de nascimento","minlength":10,"validation":_vm.$v.form.nascimento,"error":{
								required: '*Campo obrigatório.',
								minLength: '*Data de nascimento inválida.',
								invalid: '*Data de nascimento inválida.',
							},"required":""},model:{value:(_vm.form.nascimento),callback:function ($$v) {_vm.$set(_vm.form, "nascimento", $$v)},expression:"form.nascimento"}})],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"id":"telefone","label":"Telefone","type":"phone","minlength":11,"placeholder":"Telefone","validation":_vm.$v.form.telefone,"error":{
								required: '*Campo obrigatório.',
								minLength: '*Número de telefone inválido.',
							},"required":""},model:{value:(_vm.form.telefone),callback:function ($$v) {_vm.$set(_vm.form, "telefone", $$v)},expression:"form.telefone"}})],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"email","label":"Novo E-mail","placeholder":"Novo E-mail","type":"email","validation":_vm.$v.form.email,"error":{
								required: '*Campo obrigatório.',
								email: '*Email no formato inválido.'
							},"required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"nova_senha","type":"password","label":"Nova Senha","validation":_vm.$v.form.novaSenha,"error":{
								minLength: '*A quantidade de caracteres é menor que 8.',
								invalid: '*Campo inválido.',
								required: '*Campo obrigatório.',
								strong: '*A senha deve conter números e letras maiúsculas e minúsculas.'
							},"required":""},model:{value:(_vm.form.novaSenha),callback:function ($$v) {_vm.$set(_vm.form, "novaSenha", $$v)},expression:"form.novaSenha"}}),_vm._m(0)],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"confirma_nova_senha","type":"password","label":"Confirma Nova Senha","validation":_vm.$v.form.confirmaNovaSenha,"error":{
								minLength: '*A quantidade de caracteres é menor que 8.',
								invalid: '*Campo inválido.',
								required: '*Campo obrigatório.',
								strong: '*A senha deve conter números e letras maiúsculas e minúsculas.',
								sameAs: '*A senha e a confirmação devem ser a mesma.'
							},"required":""},model:{value:(_vm.form.confirmaNovaSenha),callback:function ($$v) {_vm.$set(_vm.form, "confirmaNovaSenha", $$v)},expression:"form.confirmaNovaSenha"}})],1),_vm._m(1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-paddingless"},[_c('p',{staticClass:"paragraph pt-4 pl-5"},[_vm._v(" A senha deve conter: 8 digitos, letras minúsculas e"),_c('br'),_vm._v(" maiúsculas, números e algum caracter especial ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-12 has-text-centered mt-5"},[_c('button',{staticClass:"button is-primary is-rounded"},[_vm._v(" Alterar e-mail ")])])}]

export { render, staticRenderFns }